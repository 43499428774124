/*$white:    #fff !default;
  $gray-100: #f8f9fa !default;
  $gray-200: #e9ecef !default;
  $gray-300: #dee2e6 !default;
  $gray-400: #ced4da !default;
  $gray-500: #adb5bd !default;
  $gray-600: #6c757d !default;
  $gray-700: #495057 !default;
  $gray-800: #343a40 !default;
  $gray-900: #212529 !default;
  $black:    #000 !default;
  $brown: #B59F62;
  $gold: #AF9500;
  $lessgold: #C9B037;
  $silver: #D7D7D7;
  $bronze: #AD8A56;

  $teal: #40FFDC;
  $black: #333;
  $primary: #333;
  $secondary: #666a70;
  $dark:    $black;
  $enable-rounded: false;

  $font-family-sans-serif:      'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

  $line-height-lg:              2 !default;
  $line-height-sm:              1.7 !default;

  $font-size-base:              1rem !default;    Assumes the browser default, typically `16px`

  $article-font-size:           $font-size-base !default;
  $lead-font-size:              $font-size-base * 1.75 !default;
  $lead-font-weight:            300 !default;
  $paragragh-margin-size:       1.5rem;
  $border-color:                $gray-300 !default;

  $body-background: white;    #faf9f7;

     general
  body, input, button, select, optgroup, textarea {
       font-family:Sen;
    font-weight: 300;
    font-family: $font-family-sans-serif;
  }
  body {
    line-height: $line-height-sm;
    background: $body-background;
    color: $black;
  }
  .entry-header {
    &.with-background {
      background-repeat: no-repeat;
      background-position-x: center;
      background-size: cover;
      margin: 0 -5rem;
      padding: 5rem 5rem 3rem;
      color: white !important;
      border-radius: 10px;
    }

    blockquote {
      padding-top: 3rem;
      font-size: 3rem;
    }
  }
  .smaller-text {
    font-size: 0.75rem;
  }
  .tiny-text {
    font-size: x-small;
  }
  .low-emph {
    color: $gray-600;
    font-weight: 300;
  }
  .super-low-emph {
    color: $gray-400;
    font-weight: 300;
  }

  .centered {
    text-align: center;
  }

  .lead {
    font-size: 2rem;
    font-weight: 300;
  }

  .secondary-info {
    font-size: 0.9rem;
    color: $gray-600;
    font-weight: 300;
  }

  .handwritten {
    font-family: 'Reenie Beanie', sans-serif;
    font-weight: 400;
    font-size: 3rem;
  }

  a {
    color: $gold;
    text-decoration: none;
      text-decoration: underline;
      text-decoration-color: $gray-400;
      text-decoration-style: dotted;

    &.strong {
      text-decoration-color: darken($primary, 24%);
      text-decoration-style: solid;
      text-decoration: underline;
    }
  }

  img {
    max-width:100%;
    height:auto;
  }

  h1,h2,h3,h4,h5,h6 {
    font-weight:700;
  }

       By Dominik Biedebach @domobch


       stroke
  nav.stroke ul li a {
    position: relative;
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 0%;
      content: '.';
      color: transparent;
      background: #333;
      height: 1px;
    }
    &:hover:after {
      width: 100%;
    }
  }

  nav {
    ul {
      margin: 0;
      padding-inline-start: 0;
      display: inline;
        padding: 80px 30px 80px 100px;
      li {
        display: inline;
        padding-right: 0.7rem;
        list-style: none;
        width: 100%;
        &.icons {
          padding-right: 0.3rem;
          .btn-lg {
            padding: .5rem
          }
        }
        a {
          &.btn-lg {
            @media (max-width: 600px) {
              font-size: 100%;
                svg {
                  width: 24px;
                  height: 24px;
                }
            }

            &:after {
              display: none;
            }
          }
          &:after,
          &:before {
            transition: all .5s;
          }

          font-size: 120%;
          width: 100%;
          text-decoration: none;
        }
      }
    }

    a {
      color: $secondary;
      &:hover {
        color: $secondary;
        text-decoration: none;
      }
    }
  }

  .secondary-nav {
    a {
      text-decoration: underline;
    }
  }

  .site-header-container {
       background-color: white;
    padding-top: 2rem;
    border-bottom: 1px solid $gray-300;
    margin-bottom: 1rem;
  }

  .site-header {
    padding-bottom: 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .logo-container {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
      img {
        max-height: 3rem;
        padding-right: 2rem;
      }

      &.centered {
        width: 100%;
        align-items: center;
        justify-content: center;
      }
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      align-content: end;
    }
  }

  .site-title {
    line-height: 0.8;
    color: $primary;
    margin-bottom: 0;
    font-size: 4rem;
    font-family: 'Reenie Beanie', sans-serif;
    font-weight: 400;
  }

  .site-hero {
    padding-top: 1rem;
    font-size: 2.5rem;
    line-height: 1.3;
    font-family: 'Dosis', sans-serif;
    font-weight: 400;
  }

  .main-content {
    margin-top: 1rem;
    padding-top: 1rem;
  }

  .bigradius {
    border-radius: 40px;
  }
  .mb-neg-30 {
    margin-bottom:-30px;
  }
  .btn {
    min-width: 180px;
  }
    blockquote {
      font-family: Times New Roman;
      font-style: italic;
      font-size: 20px;
      position:relative;
      border-left:1px solid $dark;
      margin-left:30px;
      padding-left:15px;
      cite {
        font-weight:700;
      }
    }

  .callout {
    padding: 2rem;
    margin: 0 auto 1.5rem auto;

    &.small {
      padding: 1rem;
      margin: 0 auto 0 auto;

      p {
        margin: 0;
        padding: 0 1rem;
      }
    }

    &.outlined {
      background: none;
      color: #333;
      border: 5px solid #f2f2f2;
      padding: 20px;
    }

    &.gray {
      background: #f2f2f2;
      color: #333;
    }

    &.blue {
      background: #d0e2eb;
    }
  }


  .callout-article {

  }

  .callout-wine {
    article {
      border-radius: 1rem;
      border-width: 4px;
      overflow: hidden;
    }
  }

  blockquote {
    margin: 0 auto;
    font-family: 'Reenie Beanie', cursive;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 100;
    font-size: 2rem;
    max-width: 650px;
    line-height: 1.4;
    position: relative;
    padding: 1.5rem .5rem .5rem;
    text-align: center;
  }


    cite {
      line-height: 3;
      text-align: left;
    }

  .page-header-with-menu {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }


  .wine-data {
    .attribute-icon {
      margin-right: 1rem;
    }
    td {
      min-width: 150px;
      padding: 0.5rem;
    }
  }

  .featured-image, .firstpage .blog-grid-item:nth-child(1), .firstpage .blog-grid-item:nth-child(2), .firstpage .blog-grid-item:nth-child(3) {
    animation: fade-up 0.7s ease-out forwards running;
  }

  figcaption {
    padding-top: 1rem;
    font-size: 1rem;
    font-style: italic;
  }

     blog grid
  .blog-grid-container {
    width: auto;
    margin: 1rem auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 30px;
  }

  .wine-callout-grid-container {
    grid-template-columns: 1fr 1fr !important;
  }

  .wine-grid-item {
    &.wine-item--expanded {
      .img-thumb {
        height: 100%;
        max-width: initial;
        max-height: initial;
        padding: 1rem;
      }

      .card-body {
        padding: 1rem;
      }
      .card-title {
        a {
          font-size: 3rem;
        }
      }
    }
  }

  .box-grid-item {
    border: 1px solid $gray-200;
    color: #333;
    padding: 0;

    .card {
      border:0;
      &:hover {
        .card-title a {
          background-size: 100% 100%;
        }
      }
    }
    .card-title {
      font-weight:700;
      font-size: 1.5rem;
      line-height:1.4;
      a {
        text-decoration:none;
        background-image: linear-gradient(180deg, transparent 89%, $primary 0);
        background-repeat: no-repeat;
        background-size: 0 100%;
        transition: background-size .6s ease;
        position:relative;
        &:hover {
          text-decoration:none;
        }
      }
    }
    .card-body {
      p {
        font-weight:400;
        font-size: 1rem;
        line-height:1.8;
        margin-top:15px;
      }
    }

    .card-footer {
      font-size: 0.9rem;
      border-top:0;
      padding: 1rem 1.25rem;
      a {
        color:inherit;
        font-weight:700;
      }
    }
    .post-date {
      float:right;
      margin-top: 10px;
    }
    .img-thumb {
      object-fit:cover;
      height:240px;
      width:100%;
      object-position:bottom;
    }
  }

  .blog-grid-item {
    display: flex;
    align-items:center;

    .card-footer {
      display: none;
    }
  }

  .recommendation-grid-item {
    .card {
      background-color: inherit !important;
    }
    .card-body {
      p {
        font-weight: 300 !important;
        margin: 1rem 0 0.5rem;
      }
      padding-bottom: 0 !important;
    }
    .card-title {
      font-size: 1rem !important;
    }
  }

  .wine-grid-item {
    .card-title {
      margin: 0.5rem 0 0;
      font-weight: 400;
      font-size: 1.2rem;
    }
    .card-body {
      padding: 0.7rem;

      p {
        margin: 1rem 0 0 0;
      }
    }
    .card-footer-text {
      margin-bottom: 1rem;
    }
    .card-fixed-footer {
      height: 8px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .score-footer {
      padding: 0;
      display: block;
      position: absolute;
      bottom: 12px;
      left: 0;
      right: 0;
    }
    .img-thumb {
      max-height: 240px;
      max-width: 120px;
    }

    .img-thumb-svg {
      height: 240px;
      width: 120px;
    }

    .img-thumb-filler {
      height: 240px;
      width: 120px;
      background-color: $gray-200;
    }
  }

    pagination
  .pagination {
    justify-content:center;
    a {
      background: rgba(0,0,0,0.05);
      color:#000;
      cursor:pointer;
      &:hover {
        background: $primary;
        text-decoration:none;
      }
    }
    a, span  {
      width: 40px;
      height: 40px;
      display: inline-block;
      border-radius: 50%;
      text-align: center;
      line-height: 40px;
    }
    span {
      border:0;
    }
  }

     Play with the values
  $radius: 7px;
  $skew: 25deg;
  $main-color: white;

  .skew-menu{
    text-align:center;
    margin-top:9em;
  }

  .skew-menu ul{
    display:inline-block;
    margin:0;
    padding:0;
    list-style:none;
    transform:skew(-$skew);
  }

  .skew-menu ul li{
    background:#fff;
    float:left;
    border-right:1px solid #eee;
    box-shadow:0 1px 1px rgba(0,0,0,0.1);
      text-transform:uppercase;
    color:#555;
    font-weight:bolder;
    transition:all 0.3s linear;

    &:first-child{
      border-radius:$radius 0 0 $radius;
    }

    &:last-child{
      border-right:none;
      border-radius:0 $radius $radius 0;
    }

    &:hover{
      background:#eee;
        color: white;
    }

    a{
      display:block;
      padding:1em 2em;
      color:inherit;
      text-decoration:none;
      transform:skew($skew);
    }
  }

    newsletter
  .newsletter {
    form {
      margin: 0 auto;
    }
    h4 {
      line-height:1.3;
    }
    margin-top: 80px;
  }

  .formkit-form {
    h2, p {
      margin: 0 !important;
    }
  }

     blog
  .entry-header {
    &--constrained {
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .article-post, .comments, .rating, .authorbox {}
  .article-post--constrained {
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
  }

  .masthead {
    padding-bottom: 1rem;
    padding-top: 0.5rem;

    &-title {
      font-weight: 300;
      font-size: 1.5rem;
      line-height: 1.2;
      color: $secondary;
      margin-bottom: 0;
      margin-top: 0;

      a {
        color: inherit;
      }

      small {
        font-size: 75%;
        font-weight: 400;
        opacity: 0.5;
      }
    }
  }
  .post-title {
    font-size: 3rem;
    font-family: 'Dosis', sans-serif;
    font-weight: 400;
    line-height: 1.2;
  }
  .post-subtitle {
    font-weight: 300;
    font-size: 1.5rem;
    padding: 1rem 0 .5rem;
    line-height: 1.2;
    color: $secondary;
  }
  .entry-featured-image {
    &--constrained {
      width:940px;
    }
    max-width: 100%;
    text-align:center;
    margin-left:auto;
    margin-right: auto;
    margin-top:50px;
    margin-bottom:50px;
  }
  .image-shadow {
    -webkit-box-shadow: 0 7px 50px 0 rgba(84,110,122,.75);
    -moz-box-shadow: 0 7px 50px 0 rgba(84,110,122,.75);
    box-shadow: 0 7px 50px 0 rgba(84,110,122,.75);
  }
  .entry-header {
    .author-thumb {
      width:50px;
      border-radius:50%;
      margin-right:10px;
      border:2px solid darken($primary,20%);
      padding:2px;
    }
  }
  .authorbox {
    margin-top:50px;
    margin-bottom:50px;
    .author-description {
      font-size: 1rem;
    }
    .author-thumb {
      width:70px;
      border-radius:50%;
      border:2px solid darken($primary,20%);
      padding:2px;
    }
  }
  .article-post {
    grid-template-areas:
      "m m s"
      "x x s";

    h1,h2 {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    h3,h4,h5,h6 {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    nav {
      font-size: 1rem;
      text-align: center;
      li:not(:first-of-type) {
        padding-left: 1rem;
        border-left: 1px solid $gray-400;
      }
    }

    font-size: $article-font-size;
    p {
      margin-top: $paragragh-margin-size;
      margin-bottom: $paragragh-margin-size;
      &.tight {
        margin-top: $paragragh-margin-size / 4;
        margin-bottom: $paragragh-margin-size / 4;
      }
    }
    div.code-toolbar > .toolbar a, div.code-toolbar > .toolbar button, div.code-toolbar > .toolbar span {
      margin-right:2px;
      margin-left:2px;
      box-shadow: 0 1px 0 0 rgba(0,0,0,0.1);
    }
  }

  .c-rating button {
    cursor:none;
  }

  div.separator {
    padding: 2rem 0 0;
    span {
      display: block;
      height: 9px;
      width: 9px;
      background-image: url('data:image/svg+xml;utf8,<svg width="9" height="9" viewBox="0 0 9 9" xmlns="http:  www.w3.org/2000/svg"><rect x="0.75" y="0.5" width="8" height="8" rx="4" fill="%23adb5bd" /></svg>');
    }

    display: flex;
    justify-content: center;
    align-items: center;

    &::before,
    &::after {
      content: '';
      display: block;
      height: 0.09em;
      min-width: 25vw;
    }

    &::before {
      background: linear-gradient(to right, rgba(240,240,240,0), $gray-500);
      margin-right: 4vh;
    }

    &::after {
      background: linear-gradient(to left, rgba(240,240,240,0), $gray-500);
      margin-left: 4vh;
    }
  }

  .list-item-container {
    position: relative;
  }


  .list-index {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    border: 2px solid black;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background-color: white;
  }

  .articles-list {
    h3:not(:first-of-type) {
      padding-top: 1rem;
      border-top: 1px solid $gray-200;
    }

    h4 {
      color: $gray-600;
      font-weight: normal;
      margin-top: 0;
    }
  }
  .comments {
    margin-top:30px;
    margin-bottom:60px;
    #comments {
      margin-top:60px;
      &.comments--show {
        display:block;
      }
    }
  }
  .related-posts {
    h2 {
    }
    margin-top:120px;
    .badge {
      font-size: 15px;
      padding:5px 7px;
      display:inline-block;
      margin: 0 3px;
    }
  }

    share
  .post-top-share {
    display: flex;
    justify-content: space-between;
    a {
      text-decoration: none;
    }
  }
  .share {
      position:fixed;left:15px;bottom:40%;z-index: 1026;
    text-align:center;
    ul {
      display: inline;
      list-style:none;
      padding-left:0;
      li {
        display: inline;
        padding-left: 15px;
        a {
          color: #b0b0b0;
        }
      }
    }
  }

    search

  .search-box {
    margin: 2rem auto;
  }

    .show-search {
      background-color: white;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      padding: 4px;
      border: 1px solid $gray-700;
      cursor:pointer;
      &:hover {
        color: darken($primary,20%);
      }
    }
    .bd-search {
      visibility: hidden;
      opacity: 0;
      transition:opacity 0.5s linear;
      &.search--show {
        visibility: visible;
        opacity: 1;
        transition:opacity 0.5s linear;
      }
    }
    .wrap-search {
      position: fixed;
      left: 30px;
      bottom: 25px;
      z-index: 1026;
    }
  .modal-open .modal {
    background: rgba(0,0,0,0.3);
  }
    .lunrsearchresult {
      .title {
        color:#000;
        font-weight:700;
        line-height:1.2;
        display:block;
        font-size:20px;
        margin-bottom:5px;
        &:hover {
          color: darken($primary,20%);
        }
      }
      span.body {
        color: $gray-500;
        line-height:1.7;
        display:block;
        margin-bottom:10px;
      }
      a {
        &:hover {
          text-decoration:none;
        }
      }
      span.url {
        display:none;
      }
    }
  body.active-side {
    .bd-search {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.5s linear;
    }
  }
    footer
  .footer {
    margin-top:120px;
    padding:30px;
    border-top:1px solid $gray-200;
    font-size: 1rem;
    background:#fff;
    .row div span {
      padding: 0 0.3rem;
      border-right: 1px solid $gray-400;

      &:last-of-type {
        border-right: none;
      }
    }
  }

    responsive tweaks

  @media (min-width: 1440px) {
    .container, .container-sm, .container-md, .container-lg {
      max-width: 1140px;
    }
  }
  @media (max-width: 1024px) {
    .container {
        width: 90%;
        max-width:90%;
      padding-left:80px;
    }
       2 cols
    .blog-grid-container {
      grid-template-columns: 1fr 1fr;
    }
      .newsletter img.newsletter-logo {
          max-height: 50px;
          float: left;
      }
  }
  @media (min-width: 1000px) {
    .wine-notes-post {
      grid-template-columns: minmax(0, 1fr) 300px !important;
      .post-second-section {
        max-width: 300px !important;
      }
    }
  }
  @media (max-width: 1000px) {
    .page-header-with-menu {
      display: block;
    }
    .site-header {
      padding-bottom: 0;
      margin-bottom: 1rem;
      display: block;
      .logo-container {
        img {
          max-height: 2.5rem;
          padding-right: 1rem;
        }
        padding-bottom: 1rem;
        span {
          font-size: 3.5rem;
        }
      }
      ul {
        padding-inline-start: 0;
        padding-top: 1rem;
        li {
          padding-right: 0.3rem;
          padding-left: 0.3rem;
        }
      }
    }
    .post-top-share {
      display: block;
    }
    .blog-grid-container {
      margin: 0 auto;
    }
    .share {
      padding-top: 0.5rem;
      text-align: left;
      ul {
        li {
          display:inline-block;
          padding-left: 8px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .entry-header {
      blockquote {
        font-size: 2rem;
      }
    }
    .post-title {
      font-size: 2rem;
    }
    .blog-grid-container {
      grid-template-columns: 1fr;
    }
    .wine-callout-grid-container {
      grid-template-columns: 1fr !important;
    }
    .firstpage .blog-grid-item:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    .blog-grid-item .img-thumb,
    .firstpage .blog-grid-item:nth-child(1) .img-thumb,
    .firstpage .blog-grid-item:nth-child(2) .img-thumb,
    .firstpage .blog-grid-item:nth-child(3) .img-thumb {
      height:auto;
    }
    .wine-data {
      .attribute-icon {
        max-width: 30px;
        margin-right: 0.5rem;
      }
      td {
        min-width: 115px;
      }
    }
    .lead {
      font-size: 1.15rem;
      font-weight: 300;
    }
  }
  @media (min-width: 768px) {
    figure {
      margin: 3.5rem 0;
    }

    .article-post {
      .hero-container {
        margin: 0 -5rem;
      }
    }

    .wine-notes-post {
      display: grid;
      grid-template-columns: minmax(0, 1fr) 250px;
      grid-gap: 0;

      .post-first-section {
        grid-area: m;
      }
      .post-second-section {
        padding-left: 1rem;
        grid-area: s;
        max-width: 250px;
        figure {
          margin-top: 1rem;
        }
      }
      .post-third-section {
        grid-area: x;
      }
    }

    .odd-layout {
      display: flex;
      flex-direction: row;
      aside {
        min-width: 150px;
        margin-left: 2rem;
      }

      figure {
        margin: 0 0 1rem;
      }
    }

    .even-layout {
      display: flex;
      flex-direction: row-reverse;
      aside {
        min-width: 150px;
        margin-right: 2rem;
      }

      figure {
        margin: 0 0 1rem;
      }
    }

    blockquote:before,
    blockquote:after {
      position: absolute;
      color: #bab6b2;
      font-size: 8rem;
      width: 4rem;
      height: 4rem;
    }

    blockquote:before {
      content: '“';
      left: -4rem;
      bottom: 5rem;
    }

    blockquote:after {
      content: '”';
      right: -4rem;
      bottom: 4rem;
    }


    .firstpage {
      .blog-grid-item {
        &:nth-child(1) {
          display: flex;
          .card {
            flex-direction: row;
            > *:first-child {
              width: 70%;
            }
            > *:last-child {
              width: 30%;
            }
          }
          .card-body {
            p {
              padding-top: 1.5rem;
            }
          }
          .card-footer {
            display: block;
            position: absolute;
            bottom: 0;
            right: 10px;
          }
          grid-column-start: 1;
          grid-column-end: 4;
          grid-row-start: 1;
          grid-row-end: 4;
            flex-direction: inherit;
            align-items: flex-start;
          font-size: 1em;
          padding:0;
            .maxthumb {
                flex: 1;
            }
          .card-title {
            font-size: 1.75rem;
          }
          .img-thumb {
            object-fit:cover;
            height:500px;
            width:100%;
            object-position:bottom;
          }
          .card-body {
              width:30%;
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    .nav-icon, .sidebar, .share {   , .wrap-search {
      position:relative;
    }
    .container {
      padding-left:15px;
    }
    .site-content {
      padding-top:0;
    }
    .related-posts {
      h2 {
        font-size: 1.4rem;
      }
    }
  }
  @media (max-width: 480px) {
    .article-post, .entry-header, .comments, .rating, .authorbox {
      padding-left:0;
      padding-right:0;
    }
    blockquote {
        margin-left:15px;
        font-size:18px;
    }
    .article-post {
      font-size: 1rem;
    }
  }
  @media (max-width: 375px) {
    .site-header {
      .logo-container {
        span {
          font-size: 2.5rem;
        }
      }
      ul {
        li {
          padding-right: 0.2rem;
          padding-left: 0.2rem;
          a {
            font-size: 95%;
          }
        }
      }
    }
    .site-hero {
      padding-top: 0;
      font-size: 1.5rem;
    }
  }

  .rating {
    padding: 1.5rem;
    margin: 0 auto;
  }

  .rating-holder {
    &-center {
      align-items: center;
      justify-content: center;
    }
    display: flex;

    margin: auto;
    img {
      width: 80px;
      height: 80px;
    }
    .score-value {
      width: 80px;
      height: 80px;
      font-size: 3rem;
    }

    &--small {
      .score-value {
        width: 60px;
        height: 60px;
        font-size: 2rem;
        text-align: center;
        line-height: 60px;
      }
    }
  }

  .rating-box {
    -webkit-box-shadow: 0 7px 50px 0 rgba(84,110,122,.75);
    -moz-box-shadow: 0 7px 50px 0 rgba(84,110,122,.75);
    box-shadow: 0 7px 50px 0 rgba(84,110,122,.75);
    width: 200px;
    height: 200px;
    background-color: $gray-300;
    border-radius: 20px;

    &--small {
      -webkit-box-shadow: 0 7px 20px 0 rgba(84,110,122,.25);
      -moz-box-shadow: 0 7px 20px 0 rgba(84,110,122,.25);
      box-shadow: 0 7px 20px 0 rgba(84,110,122,.25);
      width: 70px;
      height: 70px;
      border-radius: 10px;
    }
  }

  .rating-circle {
    img {
      width: 40px;
      height: 40px;
    }
    .score-value {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $gray-300;
      font-size: 1rem;
      padding: 0.25rem;
    }
  }

  .score-100 {
    background-color: $gold;
  }
  .score-95 {
    background-color: $lessgold;
  }
  .score-90 {
    background-color: $lessgold;
  }
  .score-85 {
    background-color: $silver;
  }
  .score-80 {
    background-color: $bronze;
  }

  .shine {
    overflow: hidden;
    position: relative;
       http:  jsfiddle.net/nqQc7/512/
    &:after {
      animation: shine 5s ease-in-out  infinite;
      animation-fill-mode: forwards;
      content: "";
      position: absolute;
      top: -110%;
      left: -210%;
      width: 200%;
      height: 200%;
      opacity: 0;
      transform: rotate(30deg);

      background: rgba(255, 255, 255, 0.13);
      background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.13) 0%,
          rgba(255, 255, 255, 0.13) 77%,
          rgba(255, 255, 255, 0.5) 92%,
          rgba(255, 255, 255, 0.0) 100%
      );
    }
    &:active:after {
      opacity: 0;
    }
  }

  .hero {
    position: relative;

    display: block;
    width: 100%;
    height: 35vh;

    &__image {
      position: absolute;

      width: 100%;
      height: 35vh;

      clip: rect(0, auto, auto, 0);
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

      *
  /   Animation
  /  /

  .animation {
    &--title-in {
      animation: title-in 800ms forwards ease-in-out 750ms;
    }
    &--from-top {
      animation: from-top 350ms forwards cubic-bezier(0.65, 0.05, 0.36, 1);
    }
    &--from-bottom {
      animation: from-bottom 350ms forwards cubic-bezier(0.65, 0.05, 0.36, 1);
    }
  }

  .credit-source {
    font-size: 1rem;
    font-style: italic;
    color: $gray-600;
    text-align: right;
  }

  #markdown-toc {
    list-style: square;

    background: #f9f9f9 none repeat scroll 0 0;
       border: 1px solid #aaa;
    font-size: 80%;

    a {
      text-decoration: underline;
      text-decoration-color: $gray-400;
      text-decoration-style: dotted;
    }

    ul {
      list-style: circle inside;
      padding-left: 0;
      margin-bottom: 0;
      ul {
        list-style: lower-roman inside;
        padding-left: 1.5rem;
      }
    }
    padding-bottom: 2rem;
    border-bottom: 1px solid $border-color;
    margin-bottom: 2rem;
  }


  #attention {
    margin: 0 auto;
    display: none;
    z-index: 1026;

    @media (min-width: 768px) {
      height: 100px;
      width: 100px;
      position: fixed;
      top: 200px;
      right: 100px;
    }
    @media (max-width: 768px) {
      height: 60px;
      width: 60px;
      position: fixed;
      right: 30px;
      bottom: 25px;
      span {
        font-size: 75%;
      }
    }

    a {
      text-decoration: none;
    }

    &.shown {
      display: block;
    }

    .image {
      position: relative;
      background-color: white;
      border: 1px solid $gray-300;
      border-radius: 50%;

      img {
        padding: 1rem;
        margin: 0px;
      }
    }

    span {
      border-radius: 5px;
      padding: 0 5px;
      color: #b8353d;
      font-weight: 600;
        border: 1px solid $gray-200;
        background-color: white;
        margin: 0 auto;
      text-align: center;
      &.shown {
        display: block;
      }
      display: none;
    }
  }

  .wobble-ver-right {
    -webkit-animation: wobble-ver-right 2s both;
    animation: wobble-ver-right 2s both;
  }

  .slide-in-right {
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .text-focus-in {
    -webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }


      *
  /   Keyframes for specific animation
  /  /
  @keyframes title-in {
    0% {
      visibility: hidden;
      opacity: 0;
      transform: translateY(-25%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
  @keyframes from-top {
    0% {
      visibility: hidden;
      opacity: 0;
      transform: translateY(-30%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
  @keyframes from-bottom {
    0% {
      visibility: hidden;
      opacity: 0;
      transform: translateY(15%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }


       ----------------------------------------------
   * Generated by Animista on 2021-2-3 23:57:50
   * Licensed under FreeBSD License.
   * See http:  animista.net/license for more info.
   * w: http:  animista.net, t: @cssanimista
   * ----------------------------------------------

      *
   * ----------------------------------------
   * animation text-focus-in
   * ----------------------------------------

  @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 0.9;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 0.9;
    }
  }


       ----------------------------------------------
   * Generated by Animista on 2021-2-3 23:27:22
   * Licensed under FreeBSD License.
   * See http:  animista.net/license for more info.
   * w: http:  animista.net, t: @cssanimista
   * ----------------------------------------------

      *
   * ----------------------------------------
   * animation slide-in-right
   * ----------------------------------------

  @-webkit-keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
      transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 0.9;
    }
  }
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
      transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 0.9;
    }
  }


       ----------------------------------------------
   * Generated by Animista on 2021-2-3 23:38:0
   * Licensed under FreeBSD License.
   * See http:  animista.net/license for more info.
   * w: http:  animista.net, t: @cssanimista
   * ----------------------------------------------

      *
   * ----------------------------------------
   * animation wobble-ver-right
   * ----------------------------------------

  @-webkit-keyframes wobble-ver-right {
    0%,
    100% {
      -webkit-transform: translateY(0) rotate(0);
      transform: translateY(0) rotate(0);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
    }
    15% {
      -webkit-transform: translateY(-30px) rotate(6deg);
      transform: translateY(-30px) rotate(6deg);
    }
    30% {
      -webkit-transform: translateY(15px) rotate(-6deg);
      transform: translateY(15px) rotate(-6deg);
    }
    45% {
      -webkit-transform: translateY(-15px) rotate(3.6deg);
      transform: translateY(-15px) rotate(3.6deg);
    }
    60% {
      -webkit-transform: translateY(9px) rotate(-2.4deg);
      transform: translateY(9px) rotate(-2.4deg);
    }
    75% {
      -webkit-transform: translateY(-6px) rotate(1.2deg);
      transform: translateY(-6px) rotate(1.2deg);
    }
  }
  @keyframes wobble-ver-right {
    0%,
    100% {
      -webkit-transform: translateY(0) rotate(0);
      transform: translateY(0) rotate(0);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
    }
    15% {
      -webkit-transform: translateY(-30px) rotate(6deg);
      transform: translateY(-30px) rotate(6deg);
    }
    30% {
      -webkit-transform: translateY(15px) rotate(-6deg);
      transform: translateY(15px) rotate(-6deg);
    }
    45% {
      -webkit-transform: translateY(-15px) rotate(3.6deg);
      transform: translateY(-15px) rotate(3.6deg);
    }
    60% {
      -webkit-transform: translateY(9px) rotate(-2.4deg);
      transform: translateY(9px) rotate(-2.4deg);
    }
    75% {
      -webkit-transform: translateY(-6px) rotate(1.2deg);
      transform: translateY(-6px) rotate(1.2deg);
    }
  }

  @keyframes shine {
    10% {
      opacity: 1;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
      transition-duration: 0.7s, 0.7s, 0.15s;
      transition-timing-function: ease;
    }
    100% {
      opacity: 0;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
    }
  }

  @keyframes fade-down {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
*/

@charset "UTF-8";
body, input, button, select, optgroup, textarea {
  font-weight: 300;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  line-height: 1.7;
  background: white;
  color: #333;
}

.entry-header.with-background {
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  margin: 0 -5rem;
  padding: 5rem 5rem 3rem;
  color: white !important;
  border-radius: 10px;
}
.entry-header blockquote {
  padding-top: 3rem;
  font-size: 3rem;
}

.smaller-text {
  font-size: 0.75rem;
}

.tiny-text {
  font-size: x-small;
}

.low-emph {
  color: #6c757d;
  font-weight: 300;
}

.super-low-emph {
  color: #ced4da;
  font-weight: 300;
}

.centered {
  text-align: center;
}

.lead {
  font-size: 2rem;
  font-weight: 300;
}

.secondary-info {
  font-size: 0.9rem;
  color: #6c757d;
  font-weight: 300;
}

.handwritten {
  font-family: "Reenie Beanie", sans-serif;
  font-weight: 400;
  font-size: 3rem;
}

a {
  color: #AF9500;
  text-decoration: none;
}
a.strong {
  text-decoration-color: black;
  text-decoration-style: solid;
  text-decoration: underline;
}

img {
  max-width: 100%;
  height: auto;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

/* By Dominik Biedebach @domobch */
/* stroke */
nav.stroke ul li a {
  position: relative;
}
nav.stroke ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: #333;
  height: 1px;
}
nav.stroke ul li a:hover:after {
  width: 100%;
}

nav ul {
  margin: 0;
  padding-inline-start: 0;
  display: inline;
}
nav ul li {
  display: inline;
  padding-right: 0.7rem;
  list-style: none;
  width: 100%;
}
nav ul li.icons {
  padding-right: 0.3rem;
}
nav ul li.icons .btn-lg {
  padding: 0.5rem;
}
nav ul li a {
  font-size: 120%;
  width: 100%;
  text-decoration: none;
}
@media (max-width: 600px) {
  nav ul li a.btn-lg {
    font-size: 100%;
  }
}
nav ul li a.btn-lg:after {
  display: none;
}
nav ul li a:after, nav ul li a:before {
  transition: all 0.5s;
}
nav a {
  color: #666a70;
}
nav a:hover {
  color: #666a70;
  text-decoration: none;
}

.secondary-nav a {
  text-decoration: underline;
}

.site-header-container {
  padding-top: 2rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 1rem;
}

.site-header {
  padding-bottom: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.site-header .logo-container {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-content: end;
}
.site-header .logo-container:hover {
  text-decoration: none;
}
.site-header .logo-container img {
  max-height: 3rem;
  padding-right: 2rem;
}
.site-header .logo-container.centered {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.site-title {
  line-height: 0.8;
  color: #333;
  margin-bottom: 0;
  font-size: 4rem;
  font-family: "Reenie Beanie", sans-serif;
  font-weight: 400;
}

.site-hero {
  padding-top: 1rem;
  font-size: 2.5rem;
  line-height: 1.3;
  font-family: "Dosis", sans-serif;
  font-weight: 400;
}

.main-content {
  margin-top: 1rem;
  padding-top: 1rem;
}

.bigradius {
  border-radius: 40px;
}

.mb-neg-30 {
  margin-bottom: -30px;
}

.btn {
  min-width: 180px;
}

.callout {
  padding: 2rem;
  margin: 0 auto 1.5rem auto;
}
.callout.small {
  padding: 1rem;
  margin: 0 auto 0 auto;
}
.callout.small p {
  margin: 0;
  padding: 0 1rem;
}
.callout.outlined {
  background: none;
  color: #333;
  border: 5px solid #f2f2f2;
  padding: 20px;
}
.callout.gray {
  background: #f2f2f2;
  color: #333;
}
.callout.blue {
  background: #d0e2eb;
}

.callout-wine article {
  border-radius: 1rem;
  border-width: 4px;
  overflow: hidden;
}

blockquote {
  margin: 0 auto;
  font-family: "Reenie Beanie", cursive;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 100;
  font-size: 2rem;
  max-width: 650px;
  line-height: 1.4;
  position: relative;
  padding: 1.5rem 0.5rem 0.5rem;
  text-align: center;
}

.page-header-with-menu {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.wine-data .attribute-icon {
  margin-right: 1rem;
}
.wine-data td {
  min-width: 150px;
  padding: 0.5rem;
}

.featured-image, .firstpage .blog-grid-item:nth-child(1), .firstpage .blog-grid-item:nth-child(2), .firstpage .blog-grid-item:nth-child(3) {
  animation: fade-up 0.7s ease-out forwards running;
}

figcaption {
  padding-top: 1rem;
  font-size: 1rem;
  font-style: italic;
}

.blog-grid-container {
  width: auto;
  margin: 1rem auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 30px;
}

.wine-callout-grid-container {
  grid-template-columns: 1fr 1fr !important;
}

.wine-grid-item.wine-item--expanded .img-thumb {
  height: 100%;
  max-width: initial;
  max-height: initial;
  padding: 1rem;
}
.wine-grid-item.wine-item--expanded .card-body {
  padding: 1rem;
}
.wine-grid-item.wine-item--expanded .card-title a {
  font-size: 3rem;
}

.box-grid-item {
  border: 1px solid #e9ecef;
  color: #333;
  padding: 0;
}
.box-grid-item .card {
  border: 0;
}
.box-grid-item .card:hover .card-title a {
  background-size: 100% 100%;
}
.box-grid-item .card-title {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.4;
}
.box-grid-item .card-title a {
  text-decoration: none;
  background-image: linear-gradient(180deg, transparent 89%, #333 0);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 0.6s ease;
  position: relative;
}
.box-grid-item .card-title a:hover {
  text-decoration: none;
}
.box-grid-item .card-body p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.8;
  margin-top: 15px;
}
.box-grid-item .card-footer {
  font-size: 0.9rem;
  border-top: 0;
  padding: 1rem 1.25rem;
}
.box-grid-item .card-footer a {
  color: inherit;
  font-weight: 700;
}
.box-grid-item .post-date {
  float: right;
  margin-top: 10px;
}
.box-grid-item .img-thumb {
  object-fit: cover;
  height: 240px;
  width: 100%;
  object-position: bottom;
}

.blog-grid-item {
  display: flex;
  align-items: center;
}
.blog-grid-item .card-footer {
  display: none;
}

.recommendation-grid-item .card {
  background-color: inherit !important;
}
.recommendation-grid-item .card-body {
  padding-bottom: 0 !important;
}
.recommendation-grid-item .card-body p {
  font-weight: 300 !important;
  margin: 1rem 0 0.5rem;
}
.recommendation-grid-item .card-title {
  font-size: 1rem !important;
}

.wine-grid-item .card-title {
  margin: 0.5rem 0 0;
  font-weight: 400;
  font-size: 1.2rem;
}
.wine-grid-item .card-body {
  padding: 0.7rem;
}
.wine-grid-item .card-body p {
  margin: 1rem 0 0 0;
}
.wine-grid-item .card-footer-text {
  margin-bottom: 1rem;
}
.wine-grid-item .card-fixed-footer {
  height: 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.wine-grid-item .score-footer {
  padding: 0;
  display: block;
  position: absolute;
  bottom: 12px;
  left: 0;
  right: 0;
}
.wine-grid-item .img-thumb {
  max-height: 240px;
  max-width: 120px;
}
.wine-grid-item .img-thumb-svg {
  height: 240px;
  width: 120px;
}
.wine-grid-item .img-thumb-filler {
  height: 240px;
  width: 120px;
  background-color: #e9ecef;
}

.pagination {
  justify-content: center;
}
.pagination a {
  background: rgba(0, 0, 0, 0.05);
  color: #000;
  cursor: pointer;
}
.pagination a:hover {
  background: #333;
  text-decoration: none;
}
.pagination a, .pagination span {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
}
.pagination span {
  border: 0;
}

.skew-menu {
  text-align: center;
  margin-top: 9em;
}

.skew-menu ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  transform: skew(-25deg);
}

.skew-menu ul li {
  background: #fff;
  float: left;
  border-right: 1px solid #eee;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #555;
  font-weight: bolder;
  transition: all 0.3s linear;
}
.skew-menu ul li:first-child {
  border-radius: 7px 0 0 7px;
}
.skew-menu ul li:last-child {
  border-right: none;
  border-radius: 0 7px 7px 0;
}
.skew-menu ul li:hover {
  background: #eee;
}
.skew-menu ul li a {
  display: block;
  padding: 1em 2em;
  color: inherit;
  text-decoration: none;
  transform: skew(25deg);
}

.newsletter {
  margin-top: 80px;
}
.newsletter form {
  margin: 0 auto;
}
.newsletter h4 {
  line-height: 1.3;
}

.formkit-form h2, .formkit-form p {
  margin: 0 !important;
}

.entry-header--constrained {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.article-post--constrained {
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
}

.masthead {
  padding-bottom: 1rem;
  padding-top: 0.5rem;
}
.masthead-title {
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.2;
  color: #666a70;
  margin-bottom: 0;
  margin-top: 0;
}
.masthead-title a {
  color: inherit;
}
.masthead-title small {
  font-size: 75%;
  font-weight: 400;
  opacity: 0.5;
}

.post-title {
  font-size: 3rem;
  font-family: "Dosis", sans-serif;
  font-weight: 400;
  line-height: 1.2;
}

.post-subtitle {
  font-weight: 300;
  font-size: 1.5rem;
  padding: 1rem 0 0.5rem;
  line-height: 1.2;
  color: #666a70;
}

.entry-featured-image {
  max-width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}
.entry-featured-image--constrained {
  width: 940px;
}

.image-shadow {
  -webkit-box-shadow: 0 7px 50px 0 rgba(84, 110, 122, 0.75);
  -moz-box-shadow: 0 7px 50px 0 rgba(84, 110, 122, 0.75);
  box-shadow: 0 7px 50px 0 rgba(84, 110, 122, 0.75);
}

.entry-header .author-thumb {
  width: 50px;
  border-radius: 50%;
  margin-right: 10px;
  border: 2px solid black;
  padding: 2px;
}

.authorbox {
  margin-top: 50px;
  margin-bottom: 50px;
}
.authorbox .author-description {
  font-size: 1rem;
}
.authorbox .author-thumb {
  width: 70px;
  border-radius: 50%;
  border: 2px solid black;
  padding: 2px;
}

.article-post {
  grid-template-areas: "m m s" "x x s";
  font-size: 1rem;
}
.article-post h1, .article-post h2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.article-post h3, .article-post h4, .article-post h5, .article-post h6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.article-post nav {
  font-size: 1rem;
  text-align: center;
}
.article-post nav li:not(:first-of-type) {
  padding-left: 1rem;
  border-left: 1px solid #ced4da;
}
.article-post p {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.article-post p.tight {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}
.article-post div.code-toolbar > .toolbar a, .article-post div.code-toolbar > .toolbar button, .article-post div.code-toolbar > .toolbar span {
  margin-right: 2px;
  margin-left: 2px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
}

.c-rating button {
  cursor: none;
}

div.separator {
  padding: 2rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.separator span {
  display: block;
  height: 9px;
  width: 9px;
  background-image: url('data:image/svg+xml;utf8,<svg width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg"><rect x="0.75" y="0.5" width="8" height="8" rx="4" fill="%23adb5bd" /></svg>');
}
div.separator::before, div.separator::after {
  content: "";
  display: block;
  height: 0.09em;
  min-width: 25vw;
}
div.separator::before {
  background: linear-gradient(to right, rgba(240, 240, 240, 0), #adb5bd);
  margin-right: 4vh;
}
div.separator::after {
  background: linear-gradient(to left, rgba(240, 240, 240, 0), #adb5bd);
  margin-left: 4vh;
}

.list-item-container {
  position: relative;
}

.list-index {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  border: 2px solid black;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background-color: white;
}

.articles-list h3:not(:first-of-type) {
  padding-top: 1rem;
  border-top: 1px solid #e9ecef;
}
.articles-list h4 {
  color: #6c757d;
  font-weight: normal;
  margin-top: 0;
}

.comments {
  margin-top: 30px;
  margin-bottom: 60px;
}
.comments #comments {
  margin-top: 60px;
}
.comments #comments.comments--show {
  display: block;
}

.related-posts {
  margin-top: 120px;
}
.related-posts .badge {
  font-size: 15px;
  padding: 5px 7px;
  display: inline-block;
  margin: 0 3px;
}

.post-top-share {
  display: flex;
  justify-content: space-between;
}
.post-top-share a {
  text-decoration: none;
}

.share {
  text-align: center;
}
.share ul {
  display: inline;
  list-style: none;
  padding-left: 0;
}
.share ul li {
  display: inline;
  padding-left: 15px;
}
.share ul li a {
  color: #b0b0b0;
}

.search-box {
  margin: 2rem auto;
}

.modal-open .modal {
  background: rgba(0, 0, 0, 0.3);
}

body.active-side .bd-search {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
}

.footer {
  margin-top: 120px;
  padding: 30px;
  border-top: 1px solid #e9ecef;
  font-size: 1rem;
  background: #fff;
}
.footer .row div span {
  padding: 0 0.3rem;
  border-right: 1px solid #ced4da;
}
.footer .row div span:last-of-type {
  border-right: none;
}

@media (min-width: 1440px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1140px;
  }
}
@media (max-width: 1024px) {
  .container {
    padding-left: 80px;
  }

  .blog-grid-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1000px) {
  .wine-notes-post {
    grid-template-columns: minmax(0, 1fr) 300px !important;
  }
  .wine-notes-post .post-second-section {
    max-width: 300px !important;
  }
}
@media (max-width: 1000px) {
  .page-header-with-menu {
    display: block;
  }

  .site-header {
    padding-bottom: 0;
    margin-bottom: 1rem;
    display: block;
  }
  .site-header .logo-container {
    padding-bottom: 1rem;
  }
  .site-header .logo-container img {
    max-height: 2.5rem;
    padding-right: 1rem;
  }
  .site-header .logo-container span {
    font-size: 3.5rem;
  }
  .site-header ul {
    padding-inline-start: 0;
    padding-top: 1rem;
  }
  .site-header ul li {
    padding-right: 0.3rem;
    padding-left: 0.3rem;
  }

  .post-top-share {
    display: block;
  }

  .blog-grid-container {
    margin: 0 auto;
  }

  .share {
    padding-top: 0.5rem;
    text-align: left;
  }
  .share ul li {
    display: inline-block;
    padding-left: 8px;
  }
}
@media (max-width: 768px) {
  .entry-header blockquote {
    font-size: 2rem;
  }

  .post-title {
    font-size: 2rem;
  }

  .blog-grid-container {
    grid-template-columns: 1fr;
  }

  .wine-callout-grid-container {
    grid-template-columns: 1fr !important;
  }

  .firstpage .blog-grid-item:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .blog-grid-item .img-thumb,
  .firstpage .blog-grid-item:nth-child(1) .img-thumb,
  .firstpage .blog-grid-item:nth-child(2) .img-thumb,
  .firstpage .blog-grid-item:nth-child(3) .img-thumb {
    height: auto;
  }

  .wine-data .attribute-icon {
    max-width: 30px;
    margin-right: 0.5rem;
  }
  .wine-data td {
    min-width: 115px;
  }

  .lead {
    font-size: 1.15rem;
    font-weight: 300;
  }
}
@media (min-width: 768px) {
  figure {
    margin: 3.5rem 0;
  }

  .article-post .hero-container {
    margin: 0 -5rem;
  }

  .wine-notes-post {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 250px;
    grid-gap: 0;
  }
  .wine-notes-post .post-first-section {
    grid-area: m;
  }
  .wine-notes-post .post-second-section {
    padding-left: 1rem;
    grid-area: s;
    max-width: 250px;
  }
  .wine-notes-post .post-second-section figure {
    margin-top: 1rem;
  }
  .wine-notes-post .post-third-section {
    grid-area: x;
  }

  .odd-layout {
    display: flex;
    flex-direction: row;
  }
  .odd-layout aside {
    min-width: 150px;
    margin-left: 2rem;
  }
  .odd-layout figure {
    margin: 0 0 1rem;
  }

  .even-layout {
    display: flex;
    flex-direction: row-reverse;
  }
  .even-layout aside {
    min-width: 150px;
    margin-right: 2rem;
  }
  .even-layout figure {
    margin: 0 0 1rem;
  }

  blockquote:before,
  blockquote:after {
    position: absolute;
    color: #bab6b2;
    font-size: 8rem;
    width: 4rem;
    height: 4rem;
  }

  blockquote:before {
    content: "“";
    left: -4rem;
    bottom: 5rem;
  }

  blockquote:after {
    content: "”";
    right: -4rem;
    bottom: 4rem;
  }

  .firstpage .blog-grid-item:nth-child(1) {
    display: flex;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 4;
    font-size: 1em;
    padding: 0;
  }
  .firstpage .blog-grid-item:nth-child(1) .card {
    flex-direction: row;
  }
  .firstpage .blog-grid-item:nth-child(1) .card > *:first-child {
    width: 70%;
  }
  .firstpage .blog-grid-item:nth-child(1) .card > *:last-child {
    width: 30%;
  }
  .firstpage .blog-grid-item:nth-child(1) .card-body p {
    padding-top: 1.5rem;
  }
  .firstpage .blog-grid-item:nth-child(1) .card-footer {
    display: block;
    position: absolute;
    bottom: 0;
    right: 10px;
  }
  .firstpage .blog-grid-item:nth-child(1) .card-title {
    font-size: 1.75rem;
  }
  .firstpage .blog-grid-item:nth-child(1) .img-thumb {
    object-fit: cover;
    height: 500px;
    width: 100%;
    object-position: bottom;
  }
}
@media (max-width: 600px) {
  .nav-icon, .sidebar, .share {
    position: relative;
  }

  .container {
    padding-left: 15px;
  }

  .site-content {
    padding-top: 0;
  }

  .related-posts h2 {
    font-size: 1.4rem;
  }
}
@media (max-width: 480px) {
  .article-post, .entry-header, .comments, .rating, .authorbox {
    padding-left: 0;
    padding-right: 0;
  }

  .article-post {
    font-size: 1rem;
  }
}
@media (max-width: 375px) {
  .site-header .logo-container span {
    font-size: 2.5rem;
  }
  .site-header ul li {
    padding-right: 0.2rem;
    padding-left: 0.2rem;
  }
  .site-header ul li a {
    font-size: 95%;
  }

  .site-hero {
    padding-top: 0;
    font-size: 1.5rem;
  }
}
.rating {
  padding: 1.5rem;
  margin: 0 auto;
}

.rating-holder {
  display: flex;
  margin: auto;
}
.rating-holder-center {
  align-items: center;
  justify-content: center;
}
.rating-holder img {
  width: 80px;
  height: 80px;
}
.rating-holder .score-value {
  width: 80px;
  height: 80px;
  font-size: 3rem;
}
.rating-holder--small .score-value {
  width: 60px;
  height: 60px;
  font-size: 2rem;
  text-align: center;
  line-height: 60px;
}

.rating-box {
  -webkit-box-shadow: 0 7px 50px 0 rgba(84, 110, 122, 0.75);
  -moz-box-shadow: 0 7px 50px 0 rgba(84, 110, 122, 0.75);
  box-shadow: 0 7px 50px 0 rgba(84, 110, 122, 0.75);
  width: 200px;
  height: 200px;
  background-color: #dee2e6;
  border-radius: 20px;
}
.rating-box--small {
  -webkit-box-shadow: 0 7px 20px 0 rgba(84, 110, 122, 0.25);
  -moz-box-shadow: 0 7px 20px 0 rgba(84, 110, 122, 0.25);
  box-shadow: 0 7px 20px 0 rgba(84, 110, 122, 0.25);
  width: 70px;
  height: 70px;
  border-radius: 10px;
}

.rating-circle img {
  width: 40px;
  height: 40px;
}
.rating-circle .score-value {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dee2e6;
  font-size: 1rem;
  padding: 0.25rem;
}

.score-100 {
  background-color: #AF9500;
}

.score-95 {
  background-color: #C9B037;
}

.score-90 {
  background-color: #C9B037;
}

.score-85 {
  background-color: #D7D7D7;
}

.score-80 {
  background-color: #AD8A56;
}

.shine {
  overflow: hidden;
  position: relative;
}
.shine:after {
  animation: shine 5s ease-in-out infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0) 100%);
}
.shine:active:after {
  opacity: 0;
}

.hero {
  position: relative;
  display: block;
  width: 100%;
  height: 35vh;
}
.hero__image {
  position: absolute;
  width: 100%;
  height: 35vh;
  clip: rect(0, auto, auto, 0);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

/**
* Animation
*/
.animation--title-in {
  animation: title-in 800ms forwards ease-in-out 750ms;
}
.animation--from-top {
  animation: from-top 350ms forwards cubic-bezier(0.65, 0.05, 0.36, 1);
}
.animation--from-bottom {
  animation: from-bottom 350ms forwards cubic-bezier(0.65, 0.05, 0.36, 1);
}

.credit-source {
  font-size: 1rem;
  font-style: italic;
  color: #6c757d;
  text-align: right;
}

#markdown-toc {
  list-style: square;
  background: #f9f9f9 none repeat scroll 0 0;
  font-size: 80%;
  padding-bottom: 2rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
#markdown-toc a {
  text-decoration: underline;
  text-decoration-color: #ced4da;
  text-decoration-style: dotted;
}
#markdown-toc ul {
  list-style: circle inside;
  padding-left: 0;
  margin-bottom: 0;
}
#markdown-toc ul ul {
  list-style: lower-roman inside;
  padding-left: 1.5rem;
}

#attention {
  margin: 0 auto;
  display: none;
  z-index: 1026;
}
@media (min-width: 768px) {
  #attention {
    height: 100px;
    width: 100px;
    position: fixed;
    top: 200px;
    right: 100px;
  }
}
@media (max-width: 768px) {
  #attention {
    height: 60px;
    width: 60px;
    position: fixed;
    right: 30px;
    bottom: 25px;
  }
  #attention span {
    font-size: 75%;
  }
}
#attention a {
  text-decoration: none;
}
#attention.shown {
  display: block;
}
#attention .image {
  position: relative;
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 50%;
}
#attention .image img {
  padding: 1rem;
  margin: 0px;
}
#attention span {
  border-radius: 5px;
  padding: 0 5px;
  color: #b8353d;
  font-weight: 600;
  text-align: center;
  display: none;
}
#attention span.shown {
  display: block;
}

.wobble-ver-right {
  -webkit-animation: wobble-ver-right 2s both;
  animation: wobble-ver-right 2s both;
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.text-focus-in {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/**
* Keyframes for specific animation
*/
@keyframes title-in {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-25%);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
@keyframes from-top {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-30%);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
@keyframes from-bottom {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translateY(15%);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2021-2-3 23:57:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 0.9;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 0.9;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2021-2-3 23:27:22
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 0.9;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 0.9;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2021-2-3 23:38:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation wobble-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes wobble-ver-right {
  0%, 100% {
    -webkit-transform: translateY(0) rotate(0);
    transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(6deg);
    transform: translateY(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(-6deg);
    transform: translateY(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(3.6deg);
    transform: translateY(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(-2.4deg);
    transform: translateY(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(1.2deg);
    transform: translateY(-6px) rotate(1.2deg);
  }
}
@keyframes wobble-ver-right {
  0%, 100% {
    -webkit-transform: translateY(0) rotate(0);
    transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(6deg);
    transform: translateY(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(-6deg);
    transform: translateY(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(3.6deg);
    transform: translateY(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(-2.4deg);
    transform: translateY(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(1.2deg);
    transform: translateY(-6px) rotate(1.2deg);
  }
}
@keyframes shine {
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}
@keyframes fade-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
